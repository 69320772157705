<template>
  <a-drawer
    class="activity-add"
    title="社群相关"
    @close="onClose"
    :width="720"
    :visible="addVisiable"
		:maskClosable="false"
  >
    <a-form-model ref="ruleForm" :model="form" :rules="rules">
      <a-form-model-item label="社群名称" prop="name" v-bind="formItemLayout">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="社群icon" prop="image" v-bind="formItemLayout">
        <UploadImgs
          :path="IMAGE_COMMUNITY"
          :file-list="fileList"
          :limit="1"
          @_change="handleUploadImgChange"
        ></UploadImgs>
      </a-form-model-item>
      <a-form-model-item label="用户组" prop="groupId" v-bind="formItemLayout">
        <a-select v-model="form.groupId">
          <a-select-option
            :value="item.groupId"
            v-for="item in groups"
            :key="item.id"
            >{{ item.groupName }}</a-select-option
          >
        </a-select>
      </a-form-model-item>
    </a-form-model>

    <div class="drawer-bootom-button">
      <!-- <a-popconfirm
        title="确定放弃编辑？"
        @confirm="onClose"
        okText="确定"
        cancelText="取消"
      >
        <a-button style="margin-right: 0.8rem">取消</a-button>
      </a-popconfirm> -->
			<a-button @click="onClose" style="margin-right: .8rem">取消</a-button>
      <a-button @click="handleSubmit" type="primary">提交</a-button>
    </div>
  </a-drawer>
</template>

<script>
import { IMAGE_COMMUNITY } from "../../../utils/upload-path-map";
import UploadImgs from "../../../components/upload-imgs/UploadImgs";

const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 18 },
};

export default {
  name: "CommunityAdd",
  components: { UploadImgs },
  props: {
    addVisiable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formItemLayout,
      groups: [],
      IMAGE_COMMUNITY,
      fileList: [],
      form: {
        name: "",
        groupName: "",
        groupId: null,
        image: "",
      },
      rules: {
        name: [
          { required: true, message: "社群名称不能为空", trigger: "blur" },
        ],
        groupId: [
          { required: true, message: "用户组不能为空", trigger: "change" },
        ],
        // image: [
        //   { required: true, message: 'icon不能为空', trigger: 'change' }
        // ],
      },
    };
  },
  mounted() {
    this.$get("group/list").then((r) => {
      console.log("获取所有社群相关的用户组", r.data);
      this.groups = r.data || [];
    });
  },
  methods: {
    onClose() {
      // this.$refs.ruleForm.resetFields();
      this.reset();
      this.$emit("close");
    },
    reset() {
      this.$refs.ruleForm.resetFields();
      for (let key in this.form) {
        this.form[key] = undefined;
      }
      this.fileList = [];
    },
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.$post("community", { ...this.form }).then((r) => {
            // console.log("添加社群活动相关", r.data);
            this.fileList = [];
            this.$emit("success");
          });
        } else {
          return false;
        }
      });
    },
    handleUploadImgChange(fileList, urls) {
      console.log("上传图片组件传过来的值", urls);
      this.form.image = urls.length?urls[0]['filePath']:'';
      this.fileList = fileList;
    },
  },
};
</script>