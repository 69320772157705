<template>
  <a-card :bordered="false" class="card-area">
    <div class="operator">
      <a-button @click="addRecord">新增</a-button>
      <a-button @click="batchDelete">删除</a-button>
    </div>
    <a-table 
      ref="TableInfo" 
      :row-selection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" 
      @change="handleTableChange" 
      :pagination="pagination" 
      bordered 
      :loading="loading" 
      :columns="columns" 
      :data-source="dataSource"
      :rowKey="(record) => record.id"
    >
      <template slot="icon" slot-scope="text, record">
        <img style="width: 60px; "  :src="$store.state.baseinfo.filedomain + text" title="社群icon" alt="社群icon" />
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-icon type="edit" theme="twoTone" twoToneColor="#4a9ff5" @click="editRecord(record)" title="修改"></a-icon>
      </template>
    </a-table>
    <CommunityEdit ref="communityEdit" @close="handleEditClose" @success="handleEditSuccess" :editVisiable="editVisiable" />
    <CommunityAdd ref="communityAdd" @close="handleAddClose" @success="handleAddSuccess" :addVisiable="addVisiable" />
  </a-card>
</template>

<script>
import CommunityAdd from "./CommunityAdd"
import CommunityEdit from "./CommunityEdit"

const columns = [
  {
    title: "社群名称",
    dataIndex: "name",
    width: 300
    // ellipsis: true
  },
  {
    title: "社群icon",
    dataIndex: "image",
    scopedSlots: { customRender: 'icon' },
    width: 200
    // ellipsis: true
  },
  {
    title: "用户组",
    dataIndex: "groupName",
    // scopedSlots: { customRender: 'goupName' },
    width: 200,
    ellipsis: true
  },
  {
    title: "操作",
    dataIndex: "operation",
    scopedSlots: { customRender: 'operation' },
    width: 100,
  }
];


export default {
  components: {CommunityAdd, CommunityEdit},
  data() {
    return {
      columns,
      dataSource: [],
      selectedRowKeys: [],
      queryParams: {},
      paginationInfo: null,
      pagination: {
        pageSizeOptions: ['10', '20', '30', '40', '100'],
        defaultCurrent: 1,
        defaultPageSize: 10,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      loading: false,
      editVisiable: false,
      addVisiable: false
    }
  },
  mounted() {
    this.fetch();
  },
  methods: {
    editRecord(record) {
      this.editVisiable = true;
      this.$refs.communityEdit.setFormFields(record);
    },
    handleEditClose() {
      this.editVisiable = false;
    },
    handleEditSuccess() {
      this.editVisiable = false;
      this.$message.success("修改社群相关成功！");
      this.fetch();
    },
    addRecord() {
      this.addVisiable = true;
    },
    handleAddClose() {
      this.addVisiable = false;
    },
    handleAddSuccess() {
      this.fetch();
      this.addVisiable = false;
      this.$message.success("添加社群成功！");
    },
    handleTableChange (pagination, filters, sorter) {
      this.paginationInfo = pagination
      this.fetch({
        ...this.queryParams
      })
    },
    onSelectChange (selectedRowKeys) {
      // console.log("selectedRowKeys==>", selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
    },
    search () {
      this.fetch({
        ...this.queryParams
      })
    },
    fetch(params = {}) {
      this.loading = true
      if (this.paginationInfo) {
        // 如果分页信息不为空，则设置表格当前第几页，每页条数，并设置查询分页参数
        this.$refs.TableInfo.pagination.current = this.paginationInfo.current
        this.$refs.TableInfo.pagination.pageSize = this.paginationInfo.pageSize
        params.pageSize = this.paginationInfo.pageSize
        params.pageNum = this.paginationInfo.current
      } else {
        // 如果分页信息为空，则设置为默认值
        params.pageSize = this.pagination.defaultPageSize
        params.pageNum = this.pagination.defaultCurrent
      }
      this.$get('community', {
        ...params
      }).then((r) => {
        console.log("社群==>", r.data);
        const pagination = { ...this.pagination };
        pagination.total = r.data.total;
        let rows = r.data.rows;
        // if(rows) {
        //   for (const key in rows) {
        //     rows[key]['key'] = +rows[key]['id'];
        //   }
        // }
        this.dataSource = rows;
        this.pagination = pagination;
        this.loading = false;
      })
    },
    batchDelete() {
      if(!this.selectedRowKeys.length) {
        this.$message.warning("请选择需要删除的记录");
      } else {
        let that = this;
        that.$confirm({
          title: "确定删除所选中的记录？",
          content: "当您点击确定按钮后，这些记录将会被彻底删除",
          centered: true,
          onOk () {
            let ids = that.selectedRowKeys.join();
            that.$delete(`community/${ids}`)
              .then(() => {
                that.$message.success("删除成功！");
                that.selectedRowKeys = [];
                that.fetch();
              })
              .catch(e => console.log("社群删除失败！==> ", e));
          },
          onCancel () {
            that.selectedRowKeys = [];
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../../utils/Common.less";
</style>